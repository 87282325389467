<template>
    <div>
        <b-container>
            <section class="error-page">
                <p class="error-page__text">An email has been sent to you with a link which you may use to reset your password. </p>
                <b-row class="justify-content-center mt-5">
                    <router-link to="/" class="details-button"><simple-line-icons icon="arrowRight" size="small" color="#504e70" /> Home page</router-link>
                </b-row>
            </section>
        </b-container>
    </div>
</template>

<script>
import SimpleLineIcons from 'vue-simple-line'
export default {
  components: {
    SimpleLineIcons
  }
}
</script>
